import React from "react";
import styled from "styled-components";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import {
  Section,
  BreadCrumb,
  GradientWrapper,
  SectionPageTitle,
} from "../components/Section";
import { ArrowRight, HomeIcon } from "../components/Icons";
import Img from "gatsby-image";
import BreakpointDown from "../components/Media/BreakpointDown";
import CheckOrangeImg from "../images/check-orange.svg";
import checkImage from "../images/icon-check.svg";

const TopWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 30px;
  align-items: center;
  ${BreakpointDown.lg`
     flex-direaction:column;
     text-align:center;
   `}
`;
const PageHeaiding = styled(SectionPageTitle)`
  margin-top: 0;
  @media (min-width: 1280px) {
    font-size: 42px;
  }
`;

const TopWrapperLeft = styled.div`
  flex: 1 1 35%;
  width: 35%;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  ${BreakpointDown.lg`
     flex: 1 1 100%;
     width:100%;
     margin-bottom:10px;
   `}
`;
const TopWrapperRight = styled.div`
  flex: 1 1 65%;
  width: 65%;
  padding-left: 15px;
  padding-right: 15px;
  ${BreakpointDown.lg`
     flex: 1 1 100%;
     width:100%;
   `}
`;

const Addrees = styled.div`
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;
`;
const AddressItem = styled.div`
  flex: 1 1 50%;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 30px;
  ${BreakpointDown.lg`
    margin-bottom:20px;
  `}
  ${BreakpointDown.sm`
    flex: 1 1 100%;
    margin-bottom:15px;
  `}
`;
const Title = styled.div`
  font-size: 22px;
  color: #223c7e;
  font-family: "poppins", sans-serif;
  font-weight: 600;
  margin-bottom: 0px;
  letter-spacing: 0.22px;
  ${BreakpointDown.lg`
     margin-bottom:0px;
  `}
  ${BreakpointDown.sm`
    font-size: 18px;
  `}
`;

const Description = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: #121316;
  ${BreakpointDown.sm`
    font-size: 16px;
  `}
`;

const LogoWrapper = styled.div`
  position: relative;
  width: 400px;
  max-width: 400px;
  ${BreakpointDown.lg`
    width: 100%;
    max-width: 300px;
    margin-left:auto;
    margin-right:auto;
   `}
  ${BreakpointDown.sm`
      max-width: 200px;
   `}
`;
const ManagmentProvideWrapper = styled.div`
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  ul {
      margin-top: 20px;
      margin-bottom: 20px;
      padding-left:0;
      li {
        &:before {
          background: url(${CheckOrangeImg});
        }
        ul {
          margin-bottom: 30px;
          ${BreakpointDown.md`
          margin-left:0;
          padding-left:0;
          `}
          li {
            &:before {
              background: url(${checkImage});
              background-repeat: no-repeat;
              top: 5px;
            }
          }
        }
      }
    }
  h3,
  h4 {
    font-size: 28px;
    margin-top:40px;
    ${BreakpointDown.md`
      margin-top:20px;
      font-size: 22px;
    `}
    &:after {
      content: "";
      display: inline-block;
      width: 36px;
      height: 2px;
      background: #f36224;
      vertical-align: middle;
      position: relative;
      margin-left: 10px;
    }

    @media (max-width: 767px) {
      &:after {
        display: none;
      }
    }
  } 
  h2{
    & + p{
      font-family: "Poppins", sans-serif;
    } 
  }
`;

function BlogPage(queryData) {
  let pageData = queryData.data.contentfulManagementProgramPage;
  return (
    <Layout pageName="managment-program">
      <SEO
        title={pageData.metaTitle}
        description={pageData.metaDescription.metaDescription}
      />
      <Section
        bgColor="rgba(203, 209, 226, 0.55)"
        pt="180px"
        pb="80px"
        xpt="60px"
        xpb="60px"
        mpb="40px"
      >
        <BreadCrumb top="75" xtop="15px">
          <div className="container">
            <Link to="/">
              <HomeIcon /> <ArrowRight />
            </Link>
            <Link className="line-after-heading">
              Jag Barn Management
            </Link>
          </div>
        </BreadCrumb>
        <div className="container">
          <TopWrapper>
            <TopWrapperLeft>
              <LogoWrapper>
                <Img
                  fluid={pageData.managmentFirstSection.sectionImage.fluid}
                  alt={pageData.managmentFirstSection.sectionImage.title}
                />
              </LogoWrapper>
            </TopWrapperLeft>
            <TopWrapperRight>
              <PageHeaiding
                mt="40px"
                mb="20px"
                textAlign="left"
                lgTextAlign="center"
              >
                {pageData.managmentFirstSection.headingText}
              </PageHeaiding>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    pageData.managmentFirstSection.contentText
                      .childMarkdownRemark.html,
                }}
              />
              <Addrees>
                {pageData.managmentFirstSection.features.map((item) => {
                  return (
                    <AddressItem>
                      <Title>{item.titleText}</Title>
                      {item.buttonUrl ? (
                        <a href={item.buttonUrl}>
                          <Description>
                            {item.descriptionText.descriptionText}
                          </Description>
                        </a>
                      ) : (
                        <Description>
                          {item.descriptionText.descriptionText}
                        </Description>
                      )}
                    </AddressItem>
                  );
                })}
              </Addrees>
            </TopWrapperRight>
          </TopWrapper>
        </div>
      </Section>
      <GradientWrapper>
        <Section
          pt="100px"
          pb="150px"
          xpt="60px"
          xpb="60px"
          bgColor="transparent"
        >
          <div className="container">
            <ManagmentProvideWrapper>
              <div
                dangerouslySetInnerHTML={{
                  __html: pageData.contentSection.childMarkdownRemark.html,
                }}
              />
              {/* <SectionTitle textAlign="left" mb="8px">
                Scope of Services
              </SectionTitle>
              <SubTitle>
                Please find below, the scope of services to be provided
              </SubTitle>
              <ManagmentProvide>
                <ManagmentProvideHeadig className="line-after-heading">
                  Jag Barn Managmentt will provide the following
                </ManagmentProvideHeadig>
                <ListItemWrapper>
                  <ul>
                    <li>
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium doloremque laudantium, totam rem
                      aperiam, eaque
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </li>
                    <li>
                      List of Services
                      <ul>
                        <li>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit.
                        </li>
                        <li>
                          At vero eos et accusamus et iusto odio dignissimos
                          ducimus qui blanditiis praesentium voluptatum deleniti
                          atque corrupti quos dolores et
                        </li>
                        <li>
                          At vero eos et accusamus et iusto odio dignissimos
                          ducimus qui blanditiis praesentium voluptatum deleniti
                          atque corrupti quos dolores et
                        </li>
                        <li>
                          On the other hand, we denounce with righteous
                          indignation and dislike men who are so beguiled and
                          demoralized by the charms of pleasure of the moment,
                          so blinded by desire, that they cannot foresee the
                          pain and trouble that are bound to ensue
                        </li>
                      </ul>
                    </li>
                  </ul>
                </ListItemWrapper>
              </ManagmentProvide>
              <ManagmentProvide>
                <ManagmentProvideHeadig className="line-after-heading">
                  Clients would provide the following
                </ManagmentProvideHeadig>
                <ListItemWrapper>
                  <ul>
                    <li>
                      At vero eos et accusamus et iusto odio dignissimos ducimus
                      qui blanditiis praesentium voluptatum deleniti atque
                      corrupti quos dolores et quas molestias excepturi sint
                      occaecati cupiditate non provident, similique sunt in
                      culpa qui officia
                      <ul>
                        <li>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit.
                        </li>
                        <li>
                          At vero eos et accusamus et iusto odio dignissimos
                          ducimus qui blanditiis praesentium voluptatum deleniti
                          atque corrupti quos dolores et
                        </li>
                        <li>
                          At vero eos et accusamus et iusto odio dignissimos
                          ducimus qui blanditiis praesentium voluptatum deleniti
                          atque corrupti quos dolores et
                        </li>
                        <li>
                          On the other hand, we denounce with righteous
                          indignation and dislike men who are so beguiled and
                          demoralized by the charms of pleasure of the moment,
                          so blinded by desire, that they cannot foresee the
                          pain and trouble that are bound to ensue
                        </li>
                      </ul>
                    </li>
                    <li>
                      At vero eos et accusamus et iusto odio dignissimos ducimus
                      qui blanditiis praesentium voluptatum deleniti atque
                      corrupti quos dolores et quas molestias excepturi sint
                      occaecati cupiditate non provident, similique sunt in
                      culpa qui officia
                      <ul>
                        <li>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit.
                        </li>
                        <li>
                          At vero eos et accusamus et iusto odio dignissimos
                          ducimus qui blanditiis praesentium voluptatum deleniti
                          atque corrupti quos dolores et
                        </li>
                        <li>
                          At vero eos et accusamus et iusto odio dignissimos
                          ducimus qui blanditiis praesentium voluptatum deleniti
                          atque corrupti quos dolores et
                        </li>
                        <li>
                          On the other hand, we denounce with righteous
                          indignation and dislike men who are so beguiled and
                          demoralized by the charms of pleasure of the moment,
                          so blinded by desire, that they cannot foresee the
                          pain and trouble that are bound to ensue
                        </li>
                      </ul>
                    </li>
                  </ul>
                </ListItemWrapper>
              </ManagmentProvide> */}
            </ManagmentProvideWrapper>
          </div>
        </Section>
      </GradientWrapper>
    </Layout>
  );
}
export default BlogPage;

export const query = graphql`
  query ManagementProgramPage {
    contentfulManagementProgramPage {
      pageUrl
      metaTitle
      metaDescription {
        metaDescription
      }
      managmentFirstSection {
        headingText
        contentText {
          childMarkdownRemark {
            html
          }
        }
        sectionImage {
          title
          fluid {
            src
            srcSet
            srcSetWebp
            srcWebp
            base64
            aspectRatio
          }
        }
        features {
          titleText
          descriptionText {
            descriptionText
          }
          buttonUrl
        }
      }
      contentSection {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
